import axios, { isAxiosError } from "axios";
import { useQuery } from "react-query";

export function passwordResetQueryKey(token: string) {
  return ["password-reset", token] as const;
}

export function useIsValidPasswordReset(token: string) {
  return useQuery({
    queryKey: passwordResetQueryKey(token),
    queryFn: async () => {
      try {
        const result = await axios({
          url: `/api/v1/password-resets/${token}/validate`,
        });

        if (result.status >= 200 && result.status < 300) {
          return true;
        }

        return false;
      } catch (error) {
        if (
          isAxiosError(error) &&
          typeof error.response?.status === "number" &&
          error.response?.status < 500
        ) {
          return false;
        }

        throw error;
      }
    },

    enabled: !!token,
  });
}

import { Dispatch, SetStateAction } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  FormikPasswordInputField,
  FormikSubmit,
  FormikTextInputField,
  useTheme,
  VStack,
  Link,
} from "@smartrent/ui";

import { useAcceptCompanyInvitation } from "../../lib/mutations/invitations";
import { parseFormError } from "../../lib/forms";
import { REDIRECT_DELAY } from "../../lib/constants";

interface CompanyFormProps {
  token: string;
  setInviteAccepted: Dispatch<SetStateAction<boolean>>;
}

export function CompanyForm({ token, setInviteAccepted }: CompanyFormProps) {
  const { spacing } = useTheme();

  const { mutateAsync: acceptCompanyInvitation } =
    useAcceptCompanyInvitation(token);

  return (
    <VStack spacing={spacing.sm}>
      <Formik<typeof initialValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setFieldError }) => {
          try {
            await acceptCompanyInvitation(values);
            setInviteAccepted(true);
            setTimeout(() => {
              window.location.assign("/");
            }, REDIRECT_DELAY);
          } catch (error) {
            parseFormError({
              error,
              setFieldError,
              defaultField: "email",
            });
          }
        }}
      >
        <Form>
          <VStack spacing={spacing.sm}>
            <FormikTextInputField name="email" label="Email" />
            <FormikPasswordInputField name="password" label="Password" />
            <FormikSubmit />
          </VStack>
        </Form>
      </Formik>
      <Link to="/forgot-password">Forgot password?</Link>
    </VStack>
  );
}

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .email("Must be a valid email"),
  password: Yup.string().required("Password is required"),
});

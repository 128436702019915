import { View } from "react-native";
import { Route, Switch } from "react-router-dom";

import { Home } from "./pages/home";
import { Invitation } from "./pages/invitation";
import { ForgotPassword } from "./pages/forgot-password";
import { ResetPassword } from "./pages/reset-password";

export function Router() {
  return (
    <View>
      <Switch>
        <Route exact path="/invitation">
          <Invitation />
        </Route>
        <Route exact path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>
        <Route>
          <Home />
        </Route>
      </Switch>
    </View>
  );
}

import { useMemo, useState } from "react";
import { useSearchParam } from "react-use";

import { LogoTitlePage } from "../../components/logo-title-page";
import { useDelayedPageLoader } from "../../hooks/use-delayed-page-loader";
import {
  useIsValidCompanyInvitation,
  useIsValidInviteToken,
} from "../../lib/queries/invitations";

import { CompanyForm } from "./company-form";
import { NewUserForm } from "./new-user-form";

export function Invitation() {
  const token = useSearchParam("token");

  const {
    data: isNewUserInvite,
    isLoading: inviteLoading,
    isError: inviteError,
  } = useIsValidInviteToken(token || "");
  const {
    data: isCompanyInvite,
    isLoading: companyLoading,
    isError: companyError,
  } = useIsValidCompanyInvitation(token || "");

  const [inviteAccepted, setInviteAccepted] = useState(false);
  const { isLoading: showLoadingState } = useDelayedPageLoader();

  const state: InvitePageState = useMemo(() => {
    if (inviteAccepted) return "accepted";
    if (inviteLoading || companyLoading || showLoadingState) return "loading";
    if (inviteError || companyError) return "error";
    if (!isNewUserInvite && !isCompanyInvite) return "invalid";
    if (isNewUserInvite) return "validNewUser";
    if (isCompanyInvite) return "validCompany";
    return "error";
  }, [
    companyError,
    companyLoading,
    inviteAccepted,
    inviteError,
    inviteLoading,
    isCompanyInvite,
    isNewUserInvite,
    showLoadingState,
  ]);

  return (
    <LogoTitlePage title={stateToMessage[state]}>
      <>
        {token && state === "validNewUser" ? (
          <NewUserForm token={token} setInviteAccepted={setInviteAccepted} />
        ) : null}
        {token && state === "validCompany" ? (
          <CompanyForm token={token} setInviteAccepted={setInviteAccepted} />
        ) : null}
      </>
    </LogoTitlePage>
  );
}

type InvitePageState =
  | "accepted"
  | "loading"
  | "error"
  | "invalid"
  | "validNewUser"
  | "validCompany";

const stateToMessage: Record<InvitePageState, string> = {
  accepted: "Invite accepted, you may now sign in to the app",
  loading: "Validating invite token...",
  error: "Network error",
  invalid: "Invite is not valid",
  validNewUser: "Please set a password",
  validCompany: "Sign in with your Alloy Install account to accept this invite",
};

import { PropsWithChildren } from "react";
import { View, StyleSheet } from "react-native";
import { VStack, useTheme, Typography, TextType } from "@smartrent/ui";

import { AlloyInstallLogo } from "./alloy-install-logo";

export type LogoTitlePageProps = PropsWithChildren<{
  title: string;
  type?: TextType;
}>;

export function LogoTitlePage({
  title,
  type = "title3",
  children,
}: LogoTitlePageProps) {
  const { spacing } = useTheme();

  return (
    <View style={[styles.root, { paddingHorizontal: spacing.sm }]}>
      <View style={styles.content}>
        <AlloyInstallLogo style={styles.logo} />
        <VStack spacing={spacing.md}>
          <Typography type={type} style={styles.title}>
            {title}
          </Typography>
          {children}
        </VStack>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    minHeight: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 200,
    margin: "auto",
  },
  title: {
    textAlign: "center",
  },
  content: {
    maxWidth: 600,
    width: "100%",
  },
});

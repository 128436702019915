import { useEffect, useState } from "react";

import { LOADING_ANIMATION_DURATION } from "../lib/constants";

export function useDelayedPageLoader(
  time: number = LOADING_ANIMATION_DURATION
) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, time);
  }, [time]);

  return { isLoading };
}

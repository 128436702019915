import { ComponentProps } from "react";

export function AlloyInstallLogo(props: ComponentProps<"svg">) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" {...props}>
      <defs>
        <linearGradient
          id="a"
          x1="358.1"
          y1="958.44"
          x2="591.87"
          y2="471.52"
          gradientTransform="matrix(1, 0, 0, -1, 0, 1026)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ed6368" />
          <stop offset="1" stopColor="#67489d" />
        </linearGradient>
      </defs>
      <path
        d="M697,655.85,536.21,748.1c-14.62,8.22-32.89,8.22-48.42,0l-159-92.25a35.24,35.24,0,0,1-17.36-30.14l-.91-167.14a15.55,15.55,0,0,0-22.84-13.7l-64.87,37.45c-14.62,7.3-22.84,22.83-22.84,38.36V681.43c0,15.53,11,33.79,23.75,42L486.88,875.05c6.39,3.66,15.53,5.49,23.75,5.49,9.14,0,17.36-1.83,23.75-5.49L797.5,723.44c13.71-7.3,23.76-26.48,23.76-42V515.2a34.15,34.15,0,0,0-17.36-30.14l-67.61-39.28c-11-6.39-24.66,1.83-24.66,14.62V626.63C713.45,638.5,707.06,649.46,697,655.85Z"
        fill="#fff"
      />
      <path
        d="M327,369.06l159.89-92.25c14.61-8.22,32.89-8.22,48.42,0l159.88,92.25L824,443V343.49c0-15.53-11-33.8-23.75-42l-264-152.53a54.45,54.45,0,0,0-24.67-5.48c-9.13,0-17.36,1.83-23.75,5.48l-264,152.53C211,308.78,200,328,200,343.49v98.64Z"
        fill="url(#a)"
      />
    </svg>
  );
}

import axios, { isAxiosError } from "axios";
import { useQuery } from "react-query";

export function newUserInvitationQueryKey(token: string) {
  return ["invitations", "new-user", token] as const;
}

export function useIsValidInviteToken(token: string) {
  return useQuery({
    queryKey: newUserInvitationQueryKey(token),
    queryFn: () =>
      isValidInvitation(`/api/v1/user-invitations/${token}/validate`),
    enabled: !!token,
  });
}

export function companyInvitationQueryKey(token: string) {
  return ["invitations", "company", token] as const;
}

export function useIsValidCompanyInvitation(token: string) {
  return useQuery({
    queryKey: companyInvitationQueryKey(token),
    queryFn: () =>
      isValidInvitation(`/api/v1/company-invitations/${token}/validate`),
  });
}

async function isValidInvitation(url: string): Promise<boolean> {
  try {
    const result = await axios({
      url,
    });

    if (result.status >= 200 && result.status < 300) {
      return true;
    }

    return false;
  } catch (error) {
    if (
      isAxiosError(error) &&
      typeof error.response?.status === "number" &&
      error.response?.status < 500
    ) {
      return false;
    }

    throw error;
  }
}

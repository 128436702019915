import { isAxiosError } from "axios";

export function parseFormError({
  setFieldError,
  error,
  defaultField,
}: {
  setFieldError: (field: string, description?: string) => void;
  error: unknown;
  defaultField: string;
}) {
  if (isAxiosError(error) && error.response?.data?.errors?.length > 0) {
    error.response?.data?.errors?.forEach(
      (e: { field: string; description: string | undefined }) => {
        setFieldError(e.field, e.description);
      }
    );
  } else {
    setFieldError(defaultField, "An unknown error has occurred");
  }
}

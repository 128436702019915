import {
  VStack,
  useTheme,
  FormikPasswordInputField,
  FormikSubmit,
} from "@smartrent/ui";
import { Formik, Form } from "formik";
import { SetStateAction, Dispatch } from "react";
import * as Yup from "yup";

import { REDIRECT_DELAY } from "../../lib/constants";
import { parseFormError } from "../../lib/forms";
import { useAcceptInviteMutation } from "../../lib/mutations/invitations";

interface NewUserFormProps {
  token: string;
  setInviteAccepted: Dispatch<SetStateAction<boolean>>;
}

export function NewUserForm({ token, setInviteAccepted }: NewUserFormProps) {
  const { spacing } = useTheme();

  const { mutateAsync: acceptInvite } = useAcceptInviteMutation(token);

  return (
    <Formik<typeof initialValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async ({ password }, { setFieldError }) => {
        try {
          await acceptInvite(password);
          setInviteAccepted(true);
          setTimeout(() => {
            window.location.assign("/");
          }, REDIRECT_DELAY);
        } catch (error) {
          parseFormError({
            error,
            setFieldError,
            defaultField: "password",
          });
        }
      }}
    >
      <Form>
        <VStack spacing={spacing.sm}>
          <FormikPasswordInputField name="password" label="Password" required />
          <FormikPasswordInputField
            name="confirmPassword"
            label="Confirm Password"
            required
          />
          <FormikSubmit />
        </VStack>
      </Form>
    </Formik>
  );
}

const initialValues = {
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Password confirmation is required"),
});

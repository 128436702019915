import {
  FormikPasswordInputField,
  FormikSubmit,
  useTheme,
  VStack,
} from "@smartrent/ui";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import * as Yup from "yup";
import { useSearchParam } from "react-use";

import { LogoTitlePage } from "../components/logo-title-page";
import { REDIRECT_DELAY } from "../lib/constants";
import { parseFormError } from "../lib/forms";
import { useResetPassword } from "../lib/mutations/password";
import { useIsValidPasswordReset } from "../lib/queries/password";
import { useDelayedPageLoader } from "../hooks/use-delayed-page-loader";

export function ResetPassword() {
  const { spacing } = useTheme();
  const token = useSearchParam("token");

  const {
    data: isValid,
    isLoading,
    isError,
  } = useIsValidPasswordReset(token || "");
  const { mutate: resetPassword, isSuccess } = useResetPassword(token);

  const { isLoading: showLoadingState } = useDelayedPageLoader();

  const state: ResetPasswordPageState = useMemo(() => {
    if (isSuccess) return "success";
    if (showLoadingState || isLoading) return "loading";
    if (isError) return "error";
    if (!isValid) return "invalid";
    if (isValid) return "valid";
    return "error";
  }, [isSuccess, showLoadingState, isLoading, isError, isValid]);

  return (
    <LogoTitlePage title={stateToTitle[state]}>
      {state === "valid" ? (
        <Formik<typeof initialValues>
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={({ password }, { setFieldError, setSubmitting }) => {
            resetPassword(password, {
              onSettled: () => setSubmitting(false),
              onSuccess: () => {
                setTimeout(() => {
                  window.location.assign("/");
                }, REDIRECT_DELAY);
              },
              onError: (error) => {
                parseFormError({
                  error,
                  setFieldError,
                  defaultField: "password",
                });
              },
            });
          }}
        >
          <Form>
            <VStack spacing={spacing.sm}>
              <FormikPasswordInputField
                name="password"
                label="Password"
                required
              />
              <FormikPasswordInputField
                name="confirmPassword"
                label="Confirm Password"
                required
              />
              <FormikSubmit />
            </VStack>
          </Form>
        </Formik>
      ) : null}
    </LogoTitlePage>
  );
}

type ResetPasswordPageState =
  | "success"
  | "loading"
  | "error"
  | "invalid"
  | "valid";

const stateToTitle: Record<ResetPasswordPageState, string> = {
  success: "Password reset, you may now sign in to the app",
  loading: "Validating password reset...",
  error: "Network error",
  invalid: "Password reset is not valid",
  valid: "Please set a new password",
};

const initialValues = {
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Password confirmation is required"),
});

import axios from "axios";
import { useMutation } from "react-query";

export function useRequestPasswordReset() {
  return useMutation({
    mutationFn: (email: string) => {
      return axios({
        method: "POST",
        url: "/api/v1/password-resets",
        data: {
          email,
        },
      });
    },
  });
}

export function useResetPassword(token?: string | null) {
  return useMutation({
    mutationFn: (password: string) => {
      return axios({
        method: "POST",
        url: `/api/v1/password-resets/${token}`,
        data: {
          password,
        },
      });
    },
  });
}

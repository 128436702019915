import {
  FormikSubmit,
  FormikTextInputField,
  useTheme,
  VStack,
} from "@smartrent/ui";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { LogoTitlePage } from "../components/logo-title-page";
import { parseFormError } from "../lib/forms";
import { useRequestPasswordReset } from "../lib/mutations/password";

export function ForgotPassword() {
  const { spacing } = useTheme();

  const { mutate: submitPasswordReset, isSuccess } = useRequestPasswordReset();

  return (
    <LogoTitlePage
      title={
        isSuccess
          ? "Please check your email for further instruction"
          : "Forgot Password"
      }
    >
      <Formik<typeof initialValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={({ email }, { setFieldError, setSubmitting }) => {
          submitPasswordReset(email, {
            onSettled: () => setSubmitting(false),
            onError: (error) => {
              parseFormError({
                setFieldError,
                error,
                defaultField: "email",
              });
            },
          });
        }}
      >
        <Form>
          <VStack spacing={spacing.sm}>
            <FormikTextInputField
              name="email"
              label="Email"
              disabled={isSuccess}
            />
            <FormikSubmit disabled={isSuccess} />
          </VStack>
        </Form>
      </Formik>
    </LogoTitlePage>
  );
}

const initialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .email("Must be a valid email"),
});

import axios from "axios";
import { useMutation } from "react-query";

export function useAcceptInviteMutation(token?: string | null) {
  return useMutation({
    mutationFn: (password: string) => {
      return axios({
        method: "POST",
        url: `/api/v1/user-invitations/${token}/accept`,
        data: { password },
      });
    },
  });
}

export function useAcceptCompanyInvitation(token?: string | null) {
  return useMutation({
    mutationFn: async ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) => {
      const {
        data: { access_token },
      } = await axios<{ access_token: string }>({
        method: "POST",
        url: "/api/v1/login",
        data: {
          email,
          password,
        },
      });

      return axios({
        method: "PATCH",
        url: `/api/v1/company-invitations/${token}/accept-invitation`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
    },
  });
}

import { HStack, Typography, Link, useTheme } from "@smartrent/ui";
import { StyleSheet } from "react-native";

import { AppStoreLogo } from "../components/app-store-logo";
import { GooglePlayLogo } from "../components/google-play-logo";
import { LogoTitlePage } from "../components/logo-title-page";

export function Home() {
  const { spacing } = useTheme();

  return (
    <LogoTitlePage title="Alloy Install" type="title">
      <HStack spacing={spacing.xxs} style={styles.links}>
        <Link href="https://apps.apple.com/us/app/alloy-install/id1479713872">
          <AppStoreLogo style={styles.storeButton} />
        </Link>
        <Link href="https://play.google.com/store/apps/details?id=com.smartrent.dealer">
          <GooglePlayLogo style={styles.storeButton} />
        </Link>
      </HStack>
      <Typography type="caption" style={styles.help}>
        If you need assistance, please contact SmartRent support at {"\n"}
        (844) 479 - 1555
      </Typography>
    </LogoTitlePage>
  );
}

const styles = StyleSheet.create({
  storeButton: {
    width: 180,
    height: 40,
  },
  help: {
    textAlign: "center",
  },
  links: {
    margin: "auto",
  },
});
